<template>
  <div>
    <van-action-sheet v-model="show">
      <img class="skuClose" @click="show = false" src="@/assets/images/goods/goodsDetail/skuClose.png" alt="">
      <div class="content">
        <div class="goodsInfo">
          <img :src="product.image" alt="">
          <div class="info">
            <div class="price">
              <span>¥</span>
              <span>{{ product.price }}</span>
            </div>
            <div class="name">
              {{ product.name }}
            </div>
          </div>
        </div>

        <!-- 选择规格方式 -->
        <!--        <sku-index v-if="type === SKUTYPE.INIT" @onTypeSelect="onTypeSelect"/>-->
        <!--         v-if="type !== SKUTYPE.INIT"-->
        <div class="skuBody">
          <!-- 我把保存按钮放在组件里面， 是因为不知道后端会怎么定义数据格式，
          放在组件里面的话，点击购买之后数据处理直接在子组件处理、组装， 完事了上发到SkuIndex组件，
          skuIndex则不会对数据进行处理，直接上发父组件 / 父页面 （如果有需要的话， 本场景不需要） -->
          <div class="start">
            开始智能选型
          </div>

          <img :src="product.cmimage" class="rule" alt="">

          <div style="width: 100%; background-color: #f5f5f5;" v-if="product.bzflag">
            <van-field
                style="background-color: #f0f0f0;border-radius: 5%;"
                v-model="product.message"
                rows="2"
                autosize
                type="textarea"
                maxlength="50"
                placeholder="请输入备注"
                show-word-limit
            />
          </div>
          <sku-custom ref="skuCustom"
                      :product.sync="product"
                      :after-sales-order-id.sync="afterSalesOrderId"
                      :key="timer"
                      @onSubmit="onSubmit"/>
        </div>

      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import SkuCustom from './skuCustom.vue'
// import skuIndex from './skuIndex.vue'
import SKUTYPE from './skuType'
import {mapActions} from "vuex";
import Vue from 'vue'
//skuIndex,
export default {
  components: {SkuCustom},
  name: "Sku",
  props: {
    // id: {
    //   type: String,
    //   default: "",
    // },
    afterSalesOrderId:{
      type: String,
      default: "",
    }
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.id = val
          this.queryProduct(val);
        }
      },
      immediate: true,
    },
    cartId:{
      handler(val) {
        if (val) {
          this.$refs.skuCustom.cartId = val
        }
      },
    },
    afterSalesOrderId:{
      handler(val) {
        if (val) {
          this.afterSalesOrderId = val
        }
      },
      immediate: true,
    }
  },
  data() {
    return {
      SKUTYPE,
      show: false,
      type: SKUTYPE.INIT,
      callback: null,
      product: {},
      id:"",
      cartId:"",
      timer:'',
    }
  },
  activated() {
    this.timer = new Date().getTime();
  },
  methods: {
    ...mapActions(['queryShoppingCartNum']),
    queryProduct(id) {
      this.$http.get(`/product/h5_product/queryProductInfo/${id}`).then(res => {
        let product = res.data.product;
        product.imgs = product.image.split(',');
        this.product = product;
      });
    },
    showPop(callback = null, type = SKUTYPE.INIT) {
      this.timer = new Date().getTime();
      this.type = type; // SKUTYPE
      if (callback) {
        this.callback = callback
      } else {
        this.callback = null
      }
      this.show = true
    },

    onTypeSelect(type) {
      this.type = type
    },

    onSubmit(data) {
      console.log(data.type) // JOINCART / BUY / CONFIRM
      // 所有的数据判断 处理都在组件内完成了， 这里不做任何处理， 直接上发
      // 如果有传callback进来 ， 就会把确认事件提交给外部页面，
      /** 比如这种就是打开的时候传入了回调
       this.$refs.sku.showPop((data) => {
                    // TODO
                })
       */
      if (this.callback) {
        this.callback(data)
        this.show = false
        return;
      }

      if (data.type === 'BUY') {
        this.$router.push({path:'/order/submit',query:data})
      } else if (data.type === 'JOINCART') {
        // TODO
        this.queryShoppingCartNum({userId:Vue.prototype.userId});
      } else if (data.type === 'CONFIRM') {
        // TODO
        this.$router.push({path:'/order/submit',query:data})
      }

      this.show = false
      // TODO SERVER
      // 否则就发起请求
    }
  }
}
</script>
<style lang="less" scoped>
.skuClose {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 26px;
  right: 19px;
  z-index: 11;
}

.content {
  padding: 0 18px;
  transition: all .2s;
  position: relative;

  .goodsInfo {

    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 24px 0 10px 0;
    z-index: 10;

    img {
      width: 100px;
      height: 84px;
      border-radius: 5px;
      margin-right: 15px;
    }

    .price {
      height: 15px;

      span {
        font-weight: bold;
        color: #D53A26;

        &:first-child {
          font-size: 12px;
          margin-right: 3px;
        }

        &:last-child {
          font-size: 18px;
        }
      }
    }

    .name {
      margin-top: 12px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
    }
  }


}

.start {
  height: 25px;
  background: #F3E9FE;
  border-radius: 4px;
  line-height: 25px;
  padding: 0 10px;

  font-size: 10px;
  font-weight: 400;
  color: #C226D5;
  line-height: 25px;

}


.rule {
  width: 100%;
  margin-bottom: 10px;
}
</style>
