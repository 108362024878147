<template>
  <div class="skuBody">

    <div class="main-content">
      <template v-for="(item,index) in skuList">
        <div class="subTitle">
          {{ item.skuName }}（<span>{{ item.need }}</span>）件
        </div>

        <div class="selectSku">
          <span>已选:</span>

          <template>
            <div v-for="(select,temp) in item.selectSkuList" :key="temp" @click="unSelect(index,temp)">
              <div :style="item.thisIndex === temp?'border-bottom: 1px solid #e78f8f;':''">
                <img src="./../../../assets/images/goods/goodsDetail/arrow.png" v-show="item.thisIndex === temp" alt=""
                     style="width: 0.32rem;">
                <label v-for="(unSepc,unTemp) in select.prop">
                  <span class="item" v-if=" !unSepc.specValId">{{ unSepc.specValName }}</span>
                  <van-badge :content="unSepc.num" :id="'selectSku'+temp+'_'+ unSepc.specValId + '_'+unSepc.random"
                             v-else>
                    <div class="item active">
                      <!--                      <img src="./../../../assets/images/goods/goodsDetail/skuItemClose.png" alt="">-->
                      {{ unSepc.specValName }}
                    </div>
                  </van-badge>
                </label>
              </div>
              <div class="item_product">已选择：<span>{{ selectedProduct(index, temp) }}</span></div>
              <div class="item_product">单价：<span>￥{{ select.price }}</span></div>
              <div class="item_product">总金额：<span>￥{{ select.totalPrice }}</span></div>
            </div>

          </template>
        </div>
        <div class="skuList">
          <div
              v-for="(sku,temp) in item.specificationVos" :key="sku.id"
          >
            <span class="spec_text" v-if="temp === 0">规格：</span>
            <span class="spec_text" v-else>{{ sku.name }}：</span>

            <div v-for="(skuVal,tempVal) in sku.specificationValues"
                 :key="tempVal"
                 :id="'sku' + skuVal.id"
                 :class="{skuItem: true,active: isActive(index,temp, skuVal.id), disabed: false }"
                 @click="selectSku(skuVal, index,temp)"
            >
              <div class="booking tag" v-if="skuVal.isBooking">预售</div>
              <div class="outofstock tag" v-if="!skuVal.able">缺货</div>
              {{ skuVal.name }}
            </div>

          </div>
        </div>

      </template>

      <div class="title">
        <div class="text">
          数量
        </div>
        <van-stepper button-size="20" v-model="number" min="1" :max="maxNumber"/>
      </div>

      <warm-prompt :reminder="product.tipmemo"/>


      <div class="count">
        <div class="left">
          共计包数量：<span>{{ number }}</span>
        </div>

        <div class="price">
          共计商品金额：<span>¥&nbsp;{{ total | money }}</span>
        </div>
      </div>

    </div>
    <!--$route.path.indexOf( '/goodsDetail') !== -1-->
    <!--  v-if="['/goodsDetail','/store/index'].indexOf($route.path) !== -1"  -->
    <van-row
        class="buttonGroup"
        v-if="$route.path.indexOf( '/goodsDetail') !== -1
        || $route.path.indexOf( '/store/index') !== -1 ||
        $route.path.indexOf( '/afterSales/detail') !== -1"
        :gutter="14">
      <div v-if="'' !== afterSalesOrderId">
        <van-col :span="24">
          <van-button color="#D53A26" block type="primary" round size="small" @click="onBuy">
            立即购买
          </van-button>
        </van-col>
      </div>
      <div v-else-if="null === product.storeId">
        <van-col :span="12">
          <van-button color="#FF553F" block type="primary" round size="small" @click="onJoinCart">
            加入购物车
          </van-button>
        </van-col>
        <van-col :span="12">
          <van-button color="#D53A26" block type="primary" round size="small" @click="onBuy">
            立即购买
          </van-button>
        </van-col>
      </div>

      <div v-else>
        <van-col :span="24">
          <van-button color="#FF553F" block type="primary" round size="small" @click="onJoinCart">
            加入购物车
          </van-button>
        </van-col>
      </div>
    </van-row>

    <van-row class="buttonGroup" v-else :gutter="14">
      <van-col :span="24">
        <van-button block type="primary" size="small" @click="onConfirm">
          确定
        </van-button>
      </van-col>
    </van-row>


  </div>
</template>

<script>
import warmPrompt from './warmPrompt.vue';
import {gsap} from 'gsap';
import {mapState} from 'vuex'

import { Decimal } from 'decimal.js'
import Vue from "vue";

//模拟数据 , 我们需要对它进行处理， 处理的地方在 initSkuData
export default {
  components: {warmPrompt},
  name: "SkuCustom",

  data() {
    return {
      number: 1,
      skuList: [],
      selectSkuList: [], // 二维数组，存放规格（包括id 价格 名称等内容）
      productPack: [],//商品包
      maxNumber:1,
      load: false,//加载
    }
  },
  props: {
    product: {
      type: Object,
      "default": {},
    },
    cartId: {
      type: String,
      "default": "",
    },
    afterSalesOrderId:{
      type: String,
      "default": "",
    }
  },
  watch: {
    product: {
      handler(val) {
        if (val.id) {
          this.product = val;
          this.querySku();
        }
      },
      immediate: true,
    },
    cartId: {
      handler(val) {
        if (val) {
          this.cartId = val
        }
      },
      immediate: true,
    },
    number:{
      handler(){
        this.calcInventoryIsSufficient();
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState(['deptSet']),
    total() {
      // 这里还需要合计每个规格的价格吧， 懒得造数据了
      // 理论上直接 统计 selectSkuList 这个二维数组里面的数据就可以了
      let totalPrice = 0;
      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(selectSku => {
          totalPrice = new Decimal(totalPrice).add(new Decimal(selectSku.totalPrice)).toNumber();

        })
      })

      return new Decimal(this.number).mul(new Decimal(totalPrice)).toNumber();
    },
    userId() {
      return Vue.prototype.userId;
    }
  },
  created() {
    console.log("进来了")
  },
  methods: {
    querySku() {
      this.$http.get(`/product/h5_product/queryReleasePackageCombinationSingleProduct/${this.product.id}/${Vue.prototype.deptId}/${this.product.libckflag}`).then(res => {
        this.initSkuData(res.data);
      })
    },
    isActive(index, temp, id) {
      let sku = this.skuList[index];
      return sku.selectSkuList[sku.thisIndex].prop[temp].specValId === id;
    },
    initSkuData(data) {
      // 我们需要创建一个二维数组 来存取所选的skuId
      data.forEach(e => {
        //尺码属性选择
        let specArr = e.specificationVos.map((spec, index) => {
          //"未选"+
          let newSpec = Object.assign({}, spec);
          return {
            specValId: null,
            specValName: (index > 0 ? newSpec.name : "尺码"),
            specValSku: '',
            num: this.deptSet.pdspec === 0 ? e.need : 1
          };
        });
        //多层嵌套数组分配不同内存地址 JSON.parse(JSON.stringify(specArr))
        let selectSkuList = null;
        if (this.deptSet.pdspec === 0) {
          selectSkuList = Array.from({length: 1}, () => (JSON.parse(JSON.stringify({
            id: '',
            price: 0,
            num: e.need,
            totalPrice: 0,
            name: '',
            prop: specArr
          }))));
        } else {
          selectSkuList = Array.from({length: e.need}, () => (JSON.parse(JSON.stringify({
            id: '',
            price: 0,
            num: 1,
            totalPrice: 0,
            name: '',
            prop: specArr
          }))));
        }
        e.selectSkuList = selectSkuList;
        e.thisIndex = 0;//当前操作索引
        e.specificationVos.forEach((spec, temp) => {
          spec.specificationValues.forEach(specVal => {
            specVal.able = this.isAble(e, temp, specVal)
          })
        })
      })
      this.skuList = data
      this.$forceUpdate();
    },
    isAble(sku, temp, specVal) {
      // 深拷贝 避免被影响
      let copySelectSku = JSON.parse(JSON.stringify(sku.selectSkuList[sku.thisIndex]));
      copySelectSku.prop[temp].specValSku = specVal.sku;
      let flag = sku.listMxSubProduct.some(item => {
        // eslint-disable-next-line no-unused-vars
        let i = 0;
        copySelectSku.prop.forEach(select => {
          if (select.specValSku !== '' && item.prop.indexOf(select.specValSku) !== -1 && !item.isOutOfStock) {
            i++;
          } else if (select.specValSku === '') {
            i++;
          }
        })
        return i === copySelectSku.prop.length
      })
      return flag
    },
    unSelect(index, temp) {
      this.skuList[index].thisIndex = temp;
      console.log(this.skuList[index])
    },
    selectedProduct(index, temp) {
      let skuName = '';
      let sku = this.skuList[index];
      let listMxSubProduct = sku.listMxSubProduct;
      let selectSku = sku.selectSkuList[temp];
      let propSkuArr = [];

      let can = selectSku.prop.every(select => {
        propSkuArr.push(select.specValSku)
        return select.specValId
      });

      if (can) {
        listMxSubProduct.forEach(mxSubProduct => {
          if (mxSubProduct.prop === propSkuArr.join("_")) {
            skuName = mxSubProduct.productSpecItem.name;
            selectSku.price = mxSubProduct.productSpecItem.price;

            // 乘法
            selectSku.totalPrice = new Decimal(selectSku.price).mul(new Decimal(selectSku.num)).toNumber();
            selectSku.name = skuName;
            selectSku.id = mxSubProduct.productSpecItem.mxId;
            return true;
          }
        })
      }
      this.skuList[index].selectSkuList[temp] = selectSku;
      //计算最大可选数量
      this.calculateMaximumNumberOfOptions();

      return skuName;
    },
    selectSku(item, index, tempVal) {
      if (!item.able) {
        // 如果是缺货 不可选
        return;
      }

      //获取当前选择的索引
      let thisIndex = this.skuList[index].thisIndex;

      let selectSku = this.skuList[index].selectSkuList[thisIndex];

      //筛选取消选择的商品数量
      this.skuList[index].listMxSubProduct.forEach(item => {
        /**
         *  selectSku.id =  ''或者 商品id(已选择商品)
         *  1.第一次选择为空 不会清除商品选择数量
         *  2.第二次选择已有商品id 找到对应的明细商品清除选择数量
         */
        if(selectSku.id == item.productSpecItem.mxId){
          item.selectedNum -= selectSku.num;
        }
      })

      /**
       * index 当前选择哪个包商品
       * thisIndex 当前选择包中第几个上哦
       * tempVal 当前选择商品中第几个规格
       */
      let d = this.skuList[index].selectSkuList[thisIndex].prop[tempVal];
      //第一次选择和选择其他sku
      if (!d.specValId || d.specValId !== item.id) {
        d.random = Math.floor(Math.random() * 1e3)
        d.specValId = item.id;
        d.specValSku = item.sku;
        d.specValName = item.name;
        this.skuList[index].selectSkuList[thisIndex].prop[tempVal] = d
      } else {
        //取消选择sku
        d.specValId = null;
        d.specValSku = '';
        d.specValName = (tempVal > 0 ? this.skuList[index].specificationVos[tempVal].name : "尺码");
        this.skuList[index].selectSkuList[thisIndex].prop[tempVal] = d

        //取消选择
        this.skuList[index].selectSkuList[thisIndex].id = ""
        this.skuList[index].selectSkuList[thisIndex].name = ""
        this.skuList[index].selectSkuList[thisIndex].price = 0
        this.skuList[index].selectSkuList[thisIndex].totalPrice = 0
      }



      this.skuList[index].specificationVos.forEach((spec, temp) => {
        spec.specificationValues.forEach(specVal => {
          specVal.able = this.isAble(this.skuList[index], temp, specVal)
        })
      })




      //判断库存是不是大于订单的数量
      let strSpecSku = "";
      selectSku.prop.forEach((map) => {
        strSpecSku += map.specValSku + "_";
      })
      strSpecSku = strSpecSku.slice(0, -1);
      let thisItem = null;
      let number = this.number;
      let can = this.skuList[index].listMxSubProduct.every(item => {
        let res = true;
        //属性和规格全部选完后才能组成商品 来判断库存
        if (item.prop.indexOf(strSpecSku) !== -1 && item.prop.length === strSpecSku.length) {
          //1.先选上该商品数量
          item.selectedNum += selectSku.num;
          //2.判断库存 决定是否能选择
          //当前库存-选择的库存 >= 需要选择的数量  true-> 就可以选择  false ->不可以选择
          res = item.marketable >= new Decimal(item.selectedNum).mul(new Decimal(number)).toNumber();
          //3.true-> 可以选择  false-> 不能选择 数量需要退回
          item.selectedNum = res ? item.selectedNum : item.selectedNum-1;

          thisItem = item;
        }
        return res;
      })

      if (!can) {
        this.$dialog.alert({
          message: thisItem.productSpecItem.name + '商品库存小于' + thisItem.marketable,
        }).then(() => {
          // on close
        });
        //选择当前sku
        d.specValId = null;
        d.specValSku = '';
        d.specValName = (tempVal > 0 ? this.skuList[index].specificationVos[tempVal].name : "尺码");
        this.skuList[index].selectSkuList[thisIndex].prop[tempVal] = d
        this.skuList[index].selectSkuList[thisIndex].id = ""
        this.skuList[index].selectSkuList[thisIndex].name = ""
        this.skuList[index].selectSkuList[thisIndex].price = 0
        this.skuList[index].selectSkuList[thisIndex].totalPrice = 0
        return false;
      }

      // 做一个动画
      this.doAni(d, thisIndex);

      //是否选择完所有sku
      can = selectSku.prop.every(select => {
        return null === select.specValId
      });

      //选完所有sku，当前索引是不是最大的索引 是就不增加 如果不是就+1在去选择尺码
      if (can && this.skuList[index].thisIndex < this.skuList[index].selectSkuList.length - 1) {
        this.skuList[index].thisIndex++;
      }

      this.$forceUpdate();
    },
    doAni(item, thisIndex) {

      this.$nextTick(() => {

        let formSkuItem = document.querySelector('#sku' + item.specValId);
        if (null == formSkuItem) {
          return;
        }
        let targetSkuItem = document.querySelector('#selectSku' + thisIndex + "_" + item.specValId + '_' + item.random);
        let tween = gsap.timeline({delay: 0});
        tween.from('#selectSku' + thisIndex + "_" + item.specValId + '_' + item.random, {
          duration: 1,
          ease: 'power2',
          opacity: 0,
          y: formSkuItem.offsetTop - targetSkuItem.offsetTop,
          x: formSkuItem.offsetLeft - targetSkuItem.offsetLeft,
          padding: [10, 13]
          // height: formSkuItem.offsetHeight,
          // width: formSkuItem.offsetWidth
        }, '>')
      })
    },
    onJoinCart() {
      if (!this.vaild()) {
        return;
      }
      if (!this.calcInventoryIsSufficient()) {
        return;
      }
      this.load = true;
      //1.先建立webscoket连接
      //2.再去请求
      //3.在提示
      let skuProductData = [];

      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(select => {
          skuProductData.push(select)
        })
      })
      let reserveProductData = [];
      let anyProductData = [];
      if (this.product.deliveryMode == 2) {
        reserveProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      } else if (this.product.deliveryMode == 1) {
        anyProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      }
      if (reserveProductData.length > 0) {
        this.$http.post(this.baseMallUrl + `/h5_mall/addShoppingCart/${Vue.prototype.userId}/${Vue.prototype.deptId}`, reserveProductData, {emulateJSON: true}).then(() => {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: this.load,
          });
          this.querySku();
          this.$emit('onSubmit', {type: 'JOINCART', data: {}})// 在sku 中发起请求， 这里只是对数据封装
        })
      } else if (anyProductData.length > 0) {
        this.$http.post(this.baseMallUrl + `/h5_mall/addShoppingCart/${Vue.prototype.userId}/${Vue.prototype.deptId}`, anyProductData, {emulateJSON: true}).then(() => {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: this.load,
          });
          this.querySku();
          this.$emit('onSubmit', {type: 'JOINCART', data: {}})// 在sku 中发起请求， 这里只是对数据封装
        })
      }
    },
    onBuy() {
      if (!this.vaild()) {
        return;
      }
      if (!this.calcInventoryIsSufficient()) {
        return;
      }
      let skuProductData = [];

      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(select => {
          skuProductData.push(select)
        })
      })

      let reserveProductData = [];
      let anyProductData = [];
      if (this.product.deliveryMode == 2) {//配送到校
        reserveProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          afterSalesOrderId: this.afterSalesOrderId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      } else if (this.product.deliveryMode == 1) {//快递到家
        anyProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          afterSalesOrderId: this.afterSalesOrderId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      }

      localStorage.setItem('reserveProductData', JSON.stringify(reserveProductData));
      localStorage.setItem('anyProductData', JSON.stringify(anyProductData));
      localStorage.setItem('afterSalesOrderId', this.afterSalesOrderId);
      localStorage.removeItem('store')
      this.$emit('onSubmit', {type: 'BUY', data: {}}); // 在sku 中发起请求， 这里只是对数据封装
    },
    //计算最大可选数量
    calculateMaximumNumberOfOptions(){
      let maxStock = 999;
      this.skuList.forEach(sku => {
        sku.listMxSubProduct.forEach(mx => {
          sku.selectSkuList.forEach(select => {
            if (mx.productSpecItem.mxId === select.id) {
              //当前规格最大可选的数量
              let stock = new Decimal(mx.marketable).div(new Decimal(mx.selectedNum)).toFixed(0,Decimal.ROUND_DOWN);
              if(maxStock>stock){
                //最小数量对应的商品
                //找到所有选择的商品中最小选择 数量
                maxStock = stock
              }
            }
          })
        })
      })

      if(999 == maxStock){
        this.maxNumber = 1;
      }else{
        this.maxNumber = maxStock;
      }
    },
    //计算库存是否充足
    calcInventoryIsSufficient() {
      let number = this.number;
      let productName = "";
      let maxStock = 999;
      this.skuList.forEach(sku => {
         sku.listMxSubProduct.forEach(mx => {
           sku.selectSkuList.forEach(select => {
            if (mx.productSpecItem.mxId === select.id) {
              //当前规格最大可选的数量
              let stock = new Decimal(mx.marketable).div(new Decimal(mx.selectedNum)).toFixed(0,Decimal.ROUND_DOWN);
              if(maxStock>stock){
                //最小数量对应的商品
                productName = mx.productSpecItem.name;
                //找到所有选择的商品中最小选择 数量
                maxStock = stock
              }
            }
          })
        })
      })
      let can = true;
      //如果当前选择数量大于最小选择数量 需要提示库存不足
      if (number>maxStock) {
        can = false;
        //最大数量
        this.$toast({
          message:productName + '商品最多可购买' + maxStock,
          type:"fail",
          onClose:()=>{
            this.number = maxStock;
            this.maxNumber = maxStock;
          }
        });
      } else {
        this.$toast('可以购买');
      }
      return can;
    },
    vaild() {
      /*for (let i = 0, len = this.selectSkuList.length; i < len; i++) {
        for (let n = 0, childLen = this.selectSkuList[i].length; n < childLen; n++) {
          let e = this.selectSkuList[i][n]
          if (!e || !e.skuId) {
            this.$toast('请选择完整规格');
            return false
          }
        }
      }*/
      let can = this.skuList.every(sku => {
        return sku.selectSkuList.every(select => {
          return select.prop.every(specVal => {
            return null != specVal.specValId;
          })
        })
      });


      if (!can) {
        this.$toast('请选择完整规格');
      }
      return can;
    },
    onConfirm() {
      if (!this.vaild()) {
        return;
      }
      if (!this.calcInventoryIsSufficient()) {
        return;
      }
      let skuProductData = [];

      this.skuList.forEach(sku => {
        sku.selectSkuList.forEach(select => {
          skuProductData.push(select)
        })
      })
      let reserveProductData = [];
      let anyProductData = [];
      let product = this.product;
      if (product.deliveryMode == '2') {
        reserveProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      } else if (product.deliveryMode == '1') {
        anyProductData.push({
          releaseProduct: this.product,
          skuProductData: skuProductData,
          cartId: this.cartId,
          totalNum: this.number,
          totalPrice: this.total,
        });
      }
      if(anyProductData.length>0){
        this.$emit('onSubmit', {type: 'CONFIRM', data: anyProductData}); // 在sku 中发起请求， 这里只是对数据封装
      }else if(reserveProductData.length>0){
        this.$emit('onSubmit', {type: 'CONFIRM', data: reserveProductData}); // 在sku 中发起请求， 这里只是对数据封装
      }
    }
  },
  // activated() {
  //   this.querySku();
  // }
}

</script>
<style lang="less" scoped>
.skuBody {
  padding-bottom: 70px;
}

.title {
  margin: 12px 0 15px 0;
  display: flex;
  justify-content: space-between;

  .text {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    line-height: 15px;

    span {
      color: #FF0000;
      font-weight: 500;
      font-size: 12px;
    }
  }

}

.subTitle {
  font-size: 12px;
  font-weight: 500;
  color: #000000;

  span {
    color: #f00;
    font-weight: 500;
  }
}

.selectSku {
  padding: 14px 0 19px 0;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: 12px;
  }

  .item {
    margin-left: 8px;
    background: #F2F2F2;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #999999;
    display: inline-block;
    padding: 3px 7px;
    transition: all .2s;

    &.active {
      background-color: #F9EDEB;
      color: #333;

      img {
        height: 9px;
        width: 9px;
        margin-right: 5px;
      }
    }
  }

  .item_product {
    font-size: 0.3rem;
    color: #ea3f3f;
  }
}

.skuList {
  margin-right: -15px;

  .skuItem {
    display: inline-block;
    background: #F2F2F2;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #212121;
    padding: 10px 13px;
    margin-right: 13px;
    margin-bottom: 12px;

    position: relative;

    &.active {
      background: #F9EDEB;
      border: 1px solid #D6191C;
      border-radius: 4px;
      padding: 9px 12px;
      color: #D6191C;
    }

    &.specialItem {
      &::after {
        position: absolute;
        bottom: -16px;
        transform: translateX(-50%);
        left: 50%;
        content: '';
        border-bottom: 11px solid #F9EDEB;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
    }

    .tag {
      height: 12px;
      width: 27px;
      border: 1px solid;
      border-radius: 2px;
      line-height: 12px;
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 10px;
      position: absolute;
      right: -7px;
      top: -6px;

      &.outofstock {
        border-color: #ccc;
        background: #CCCCCC;
      }

      &.booking {
        background: #D61619;
        border-color: #D61619;
      }
    }
  }
}


.count {
  display: flex;
  justify-content: space-between;
  margin: 21px 0 0 0;

  .left {
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    line-height: 10px;

    span {
      color: #D53A26;
    }
  }

  .price {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 12px;

    span {
      font-weight: 500;
      color: #D53A26;

    }
  }
}

.buttonGroup {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 18px;
  right: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
}
</style>
