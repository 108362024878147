/**
 * long description for the file
 *
 * @summary 如果需要修改参数， 直接改value就行了
 * @author LinRenChen
 *
 * Created at     : 2022-06-11 01:55:36 
 * Last modified  : 2022-06-11 01:56:00
 */


export default {
    INIT: 'init', // 初始化的时候， 下面有两个按钮选择状态
    UNIFORM: 'Uniform', //统一选择规格
    CUSTOM: 'custom', // 自定义搭配 （校服包 / 按套选择规格）
}