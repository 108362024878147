<template>
  <div class="tips">
    <div class="title">尺码提示:</div>
    <div class="content" v-html="reminder">
<!--      家长您好：校服按照孩子实际身高订购即可，切勿定大尺码。(例如：小孩身高120cm，则订购120码正合身，订购130码偏宽松），以免引起不必要的退换麻烦。特码可直接联系客服。-->
    </div>
  </div>
</template>

<script>
export default {
  name: "WarmPrompt",
  props:{
    reminder:{
      type:String,
      default:"",
    }
  }
}

</script>
<style lang="less" scoped>
.tips {
  background: #FBEBEB;
  border-radius: 5px;
  padding: 14px 18px 12px 11px;

  .title {
    font-size: 10px;
    font-weight: 400;
    color: #D53A26;
    line-height: 15px;
  }

  .content {
    text-indent: 20px;
    font-size: 10px;
    font-weight: 400;
    color: #333;
    line-height: 15px;

  }
}
</style>
